import { render, staticRenderFns } from "./index.vue?vue&type=template&id=de49245e&scoped=true&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de49245e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroTiled: require('/opt/build/repo/components/HeroTiled.vue').default,Hero: require('/opt/build/repo/components/Hero.vue').default,SlicesBlock: require('/opt/build/repo/components/SlicesBlock.vue').default})
