
import PrismicData from '~/assets/js/prismic-controller.js'
import SlicesBlock from '~/components/SlicesBlock.vue'

export default {
  name: 'Home',
  components: {
    SlicesBlock
  },
  async asyncData({ $prismic, app, store, params, error, req }) {
    const lang = app.i18n.locales.filter(i => i.iso.includes(app.i18n.locale))[0].iso
    try {
      let results = false
      results = await $prismic.api.query($prismic.predicates.at('my.page.uid', 'home'), { lang })

      const page = results.results[0]

      /* Alt Lang */
      function getAltLangNav(altLanguages) {
        return altLanguages.map(item => {
          const lang = item.lang.substr(0, 2)
          return {
            [lang]: {uid: item.uid}
          }
        })
      }
      if (page.alternate_languages.length) {
        const altLang = getAltLangNav(page.alternate_languages)
        store.dispatch('i18n/setRouteParams', altLang.reduce(a => a))
      }

      const contentTypes = []
      if (page.data.body.length){
        page.data.body.map((slice, index) => {
          if (slice.slice_type === 'content_type_list') { contentTypes.push({ slice: slice.primary, index }) }
          return slice
        })
        if (contentTypes.length) {
          const contentTypesPromises = contentTypes.map(async(contentType) => {
            try {
              const { results } = await $prismic.api.query($prismic.predicates.at('document.type', contentType.slice.type.toLowerCase()), { lang })
              page.data.body[contentType.index].items = results
            } catch (e) {
              console.error(e)
              error({ statusCode: 404, message: 'Can not fetch Content Type' })
            }
          })
          await Promise.all(contentTypesPromises)
        }
      }
      return {
        document: page.data,
        slices: page.data.body || [],
        documentUid: page.uid,
        documentLang: page.lang.substr(0, 2)
      }
    } catch (e) {
      error({ message: JSON.stringify(e), statusCode: 404 })
    }
  },
  head() {
    return PrismicData.seo(this.$nuxtI18nHead({ addSeoAttributes: true }), this.document, this.documentUid, this.documentLang)
  }
}
